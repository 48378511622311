import { mapHelper } from "@/utils/common.js";
//上报状态
const reportStatus = [
  {
    value: 1,
    label: "已上报",
  },
  {
    value: 2,
    label: "未上报",
  },
];
const { map: reportStatusMap, setOps: reportStatusOps } =
  mapHelper.setMap(reportStatus);
//房间类型
const roomType = [
  {
    value: 1,
    label: "幢-单元-室",
  },
  {
    value: 2,
    label: "幢-室",
  },
  {
    value: 3,
    label: "幢-号",
  },
  {
    value: 4,
    label: "号",
  },
];
const { map: roomTypeMap, setOps: roomTypeOps } = mapHelper.setMap(roomType);
// 成功or 失败
const isSucceedStatus = [
  {
    value: "1",
    label: "成功",
  },
  {
    value: "0",
    label: "失败",
  },
];
const { map: isSucceedStatusMap, setOps: isSucceedStatusOps } =
  mapHelper.setMap(isSucceedStatus);

export { reportStatusMap, reportStatusOps, roomTypeOps, isSucceedStatusOps };

<template>
  <div class="provincialDataInterface">
    <div class="search">
      <div class="search-r">
        <v-button :width="112" text="返回" @click="$router.back()"></v-button>
      </div>
    </div>
    <v-table :isOperateColumn="false" :tableData="tableData" :headers="headers">
    </v-table>
    <!-- <v-list
      ref="list"
      :isAdd="true"
      :border="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :operateColumnWidth="150"
      operateColumnAlign="center"
      v-bind="submitConfig"
      :isOperateColumn="false"
    >
      <template #searchOperaSlot>
        <v-button text="返回" :width="112" @click="toBack"
      /></template>
      <template #searchSlot>
        
        <v-select
          clearable
          :options="isSucceedStatusOps"
          v-model="submitConfig.extraParams.responseResult"
          @change="$refs.list.search()"
          :placeholder="'请选择'"
          label="上报结果"
        />
      </template>
    </v-list> -->
    <!-- 弹窗 -->
    <v-dialog
      :title="title"
      v-model="viewDialog"
      :close-on-click-modal="false"
      @confirm="confirm"
      :appendToBody="true"
      :isShowCancelBtn="false"
      :center="false"
      sureTxt="关闭"
    >
      <div class="json">
        <vue-json-editor
          v-model="resultInfo"
          :showBtns="false"
          :mode="'code'"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getReportedErrorLog, getTenantList } from "./api.js";
import { mapState } from "vuex";
import { isSucceedStatusMap, isSucceedStatusOps } from "./map.js";
import { _localStorage } from "@/utils/utils.js";
import vueJsonEditor from "vue-json-editor";
import { createAlinkVNodeHtml, createSpanVNodeHtml } from "@/utils/utils.js";
import { mapHelper } from "@/utils/common.js";
export default {
  name: "provincialDataInterface",
  data() {
    return {
      title: "",
      resultInfo: null,
      viewDialog: false,
      headers: [
        {
          prop: "tenantId",
          label: "社区名称",
          align: "center",
          width: 150,
          showOverflowTooltip: true,
          formatter: (row, prop) => {
            return this.formatTenantId(row, prop);
          },
        },
        {
          prop: "methodName",
          label: "接口名称",
          align: "center",
          width: 300,
          showOverflowTooltip: true,
        },
        {
          prop: "insertTime",
          label: "上报时间",
          align: "center",
          width: 200,
        },
        {
          prop: "responseResult",
          label: "结果",
          align: "center",
          width: 100,
          formatter: (row, prop) => {
            return createSpanVNodeHtml(this, row, prop);
          },
        },
        {
          prop: "requestData",
          label: "上报数据",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNodeHtml(this, row, prop, {
              txtView: row[prop] ? "查看" : "",
              cb: () => this.toView(row, prop),
            }),
        },
        {
          prop: "responseData",
          label: "返回数据",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNodeHtml(this, row, prop, {
              txtView: row[prop] ? "查看" : "",
              cb: () => this.toView(row, prop),
            }),
        },
      ],
      isSucceedStatusOps: isSucceedStatusOps(),
      submitParams: {
        id: "",
        type: "",
      },
      tenantList: [],//社区列表
      tableData: [], //表格数据
    };
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
    const { id, type } = this.$route.query;
    if (id && type) {
      this.submitParams.id = id;
      this.submitParams.type = type;
    }
    this.getReportedList();
    this.getTenantList();
  },
  mounted() {
    this.$setBreadList("查看上报日志");
  },
  components: {
    vueJsonEditor,
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
  },
  methods: {
    //获取日志数据
    getReportedList() {
      this.$axios
        .get(getReportedErrorLog, { params: this.submitParams })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = [];
            const { data } = res;
            if (Object.keys(data).length > 0) {
              this.tableData.push(data);
            }
          }
        });
    },
    //格式社区名称
    formatTenantId(row, prop) {
      const { map: tenantNamedMap } = mapHelper.setMap(this.tenantList);
      return tenantNamedMap[row[prop]];
    },
    //获取社区编码列表
    getTenantList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      this.$axios
        .post(getTenantList, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            console.log(data);
            data.forEach((item) => {
              item.value = item.tenantId;
              item.label = item.tenantName;
            });
            this.tenantList = data;
          }
        });
    },
    // 查看数据
    toView(row, prop) {
      this.resultInfo = null;
      if (prop === "requestData") {
        this.title = "查看上报数据";
      } else {
        this.title = "查看返回数据";
      }
      this.resultInfo = JSON.parse(row[prop]);
      this.viewDialog = true;
    },
    //关闭弹窗
    confirm() {
      this.viewDialog = false;
    },
    // 返回
    toBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.provincialDataInterface {
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
   
  }
  .v-table {
    height: calc(100% - 110px);
  }
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
    .gray {
      .el-button--text {
        color: #999;
      }
    }
  }
  .json {
    height: 500px;
    ::v-deep .jsoneditor-vue {
      height: 500px;
      .jsoneditor-poweredBy {
        display: none;
      }
      .ace_scrollbar {
        width: 5px !important;
      }
    }
  }
  ::v-deep .v-dialog {
    .dialog-footer {
      justify-content: flex-end;
    }
  }
}
</style>

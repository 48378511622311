//获取上报/未上报的房屋数据
const getReportedHouseInfoList = `/gateway/hc-portal/szdz/zzt/getReportedHouseInfoList`;
//获取可以订阅的小区列表
const authTenantListUrl = `/gateway/hc-portal/client/authTenantList`;
//获取小区列表
const communityListUrl = `/gateway/hc-portal/client/communityList`;
//上报房屋信息/批量
const reportedSpaceInfo = `/gateway/hc-portal/szdz/zzt/reportedSpaceInfo`;
//新增房屋信息并上报/批量
const addAndReportedHouseInfo = `/gateway/hc-portal/szdz/zzt/addAndReportedHouseInfo`;
//取消房屋信息上报
const cancelReportedHouseInfo = `/gateway/hc-portal/szdz/zzt/cancelReportedHouseInfo`;
//租户及社区编码列表
const getTenantList = `/gateway/hc-portal/szdz/tenantList`;
//查看上报错误日志
const getReportedErrorLog = `/gateway/hc-portal/szdz/zzt/getReportedErrorLog`;
export {
  getReportedHouseInfoList,
  authTenantListUrl,
  communityListUrl,
  reportedSpaceInfo,
  addAndReportedHouseInfo,
  cancelReportedHouseInfo,
  getTenantList,
  getReportedErrorLog
};
